<template>
  <div class="assignation">
    <div class="assignation-title">
      <h3>{{ $t("REPAIRS.ASSIGN_REPAIR") }}</h3>
    </div>
    <div class="assignation-inspectors">
      <h3>{{ $t("REPAIRS.REPAIRERS") }}</h3>
      <p>{{ $t("REPAIRS.ASSIGN_TO_REPAIRERS") }}</p>
      <div class="radio-selection">
        <el-radio
          v-for="role in rolesList"
          v-model="roleSelector"
          :label="role.id"
          :key="role.id"
        >
          <span
            v-html="`${$t('COMMON.ALL_USERS_WITH_ROLE', { name: role.name })}`"
          ></span>
        </el-radio>
        <el-radio v-model="roleSelector" label="-1">
          {{ $t("COMMON.OTHERS") }}
        </el-radio>
      </div>
      <Button
        v-if="!isRoleAssignment"
        class="add-inspector"
        @click="showMechanicSelectionModal = true"
      >
        <i class="fal fa-plus"></i>
        <span>{{ $t("REPAIRS.ADD_REPAIRER") }}</span>
      </Button>
      <div v-if="!isRoleAssignment && usersModel != []" class="mechanic-list">
        <el-tag
          :key="tag"
          v-for="tag in usersModel"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ $objectDenomination(getUser(tag)) }}
        </el-tag>
      </div>
    </div>

    <modal
      :show.sync="showMechanicSelectionModal"
      modal-classes="modal-secondary mechanic-list"
    >
      <template slot="header">
        <h3>{{ $t("REPAIRS.ADD_REPAIRER") }}</h3>
      </template>

      <p>{{ $t("REPAIRS.ADD_REPAIRER") }}</p>
      <div class="search-form">
        <el-input
          :placeholder="$t('REPAIRS.ADD_REPAIRER')"
          v-model="userSearch"
        >
          <i slot="prefix" class="fal fa-search"></i>
        </el-input>
      </div>
      <div class="others">
        <!-- <p>Autres</p> -->
        <div class="mechanic-checkbox">
          <el-checkbox-group v-model="usersModel">
            <el-checkbox
              v-for="user in userFiltered"
              :key="user.id"
              :label="user.id"
            >
              {{ $objectDenomination(user) }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <template slot="footer">
        <Button class="yes" @click="showMechanicSelectionModal = false">
          {{ $t("COMMON.END") }}
        </Button>
      </template>
    </modal>
  </div>
</template>

<script>
import { Checkbox, CheckboxGroup, Radio, Tag } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import _ from "lodash";

export default {
  name: "repair-form-assignation-step",

  components: {
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Radio.name]: Radio,
    [Tag.name]: Tag,
  },

  props: ["repairData", "role", "users"],

  mixins: [requestErrorMixin],

  data() {
    const repair = { ...this.repairData };
    const usersModel = this.users ?? [];
    const roleSelector = this.role ?? -1;
    return {
      usersList: [],
      rolesList: [],
      repair,
      usersModel,
      roleSelector,
      userSearch: "",
      showMechanicSelectionModal: false,
    };
  },

  computed: {
    userFiltered() {
      return this.userSearch
        ? this.usersList.filter((user) =>
            this.$objectDenomination(user)
              .toLowerCase()
              .includes(this.userSearch.toLowerCase())
          )
        : this.usersList;
    },
    isRoleAssignment() {
      return this.roleSelector != "-1";
    },
  },

  async created() {
    await this.getRoles();
    await this.getUsers();
  },

  methods: {
    async getRoles() {
      try {
        let params = {
          sort: "name",
          filter: {
            permission: this.$permissions.PERM_START_REPAIRS,
            organization: this.repair.organization.id,
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        await this.$store.dispatch("roles/list", params);

        this.rolesList = await this.$store.getters["roles/list"];
        // this.rolesList = {};
        // rolesArr.forEach((role) => {
        //   this.rolesList[role.id] = role;
        // });
      } catch (error) {
        this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async getUsers() {
      try {
        let params = {
          filter: {
            permission: this.$permissions.PERM_START_REPAIRS,
            organization: this.repair.organization.id,
          },
          // page: {
          //   number: 1,
          //   size: 10,
          // },
        };

        if (this.userSearch?.length) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              search: this.userSearch,
            },
          };
        }

        await this.$store.dispatch("users/list", params);

        this.usersList = await this.$store.getters["users/list"];
        // this.usersList = {};
        // usersArr.forEach((user) => {
        //   this.usersList[user.id] = user;
        // });
      } catch (error) {
        this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    handleClose(tag) {
      this.usersModel.splice(this.usersModel.indexOf(tag), 1);
    },
    getUser(userId) {
      return this.usersList.find((user) => user.id == userId);
    },
  },

  watch: {
    roleSelector(value) {
      this.$emit("update:role", value);
    },
    usersModel() {
      this.$emit("update:users", this.usersModel);
    },
  },
};
</script>

<style lang="scss" scoped></style>
