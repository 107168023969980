<template>
  <div class="list-item-line estimate-details-body-content">
    <div class="list-item-line-group flex-column align-items-stretch">
      <base-input :label="`${$t('REPAIRS.COMPONENTS_ESTIMATOR')}`" group>
        <input
          :placeholder="$t('REPAIRS.COMPONENTS_ESTIMATOR')"
          :name="$t('REPAIRS.COMPONENTS_ESTIMATOR')"
          :value="$objectDenomination(repair?.startedComponentsBy)"
          readonly
          class="form-control"
          :class="{
            'bg-transparent border-right-0':
              canChangeComponentsRepairManager(repair),
          }"
        />
        <div
          class="input-group-append"
          v-if="canChangeComponentsRepairManager(repair)"
        >
          <button
            class="btn border border-left-0 border-light shadow-none rounded-right"
            @click="showChangeComponentsRepairManagerModal = true"
          >
            <img src="/img/change-user.svg" alt="repair in progress" />
          </button>
        </div>
      </base-input>
    </div>
    <div class="list-item-line-group flex-column align-items-stretch">
      <base-input :label="`${$t('REPAIRS.TIMES_ESTIMATOR')}`" group>
        <input
          :placeholder="$t('REPAIRS.TIMES_ESTIMATOR')"
          :name="$t('REPAIRS.TIMES_ESTIMATOR')"
          :value="$objectDenomination(repair?.startedTimesBy)"
          readonly
          class="form-control"
          :class="{
            'bg-transparent border-right-0':
              canChangeTimesRepairManager(repair),
          }"
        />
        <div
          class="input-group-append"
          v-if="canChangeTimesRepairManager(repair)"
        >
          <button
            class="btn border border-left-0 border-light shadow-none rounded-right"
            @click="showChangeTimesRepairManagerModal = true"
          >
            <img src="/img/change-user.svg" alt="repair in progress" />
          </button>
        </div>
      </base-input>
    </div>
    <div class="list-item-line-group flex-column align-items-stretch">
      <base-input
        :label="`${$t('COMMON.APPROVER')}`"
        :placeholder="$t('COMMON.APPROVER')"
        :name="$t('COMMON.APPROVER')"
        :value="$objectDenomination(repair?.startedApproveBy)"
        readonly
      />
    </div>
    <user-selector-modal
      v-if="canChangeComponentsRepairManager(repair)"
      :filter-organization="repair.organization?.id"
      :filter-permission="$permissions.PERM_EDIT_REPAIRS_COMPONENTS"
      :header-text="$t('REPAIRS.CHANGE_COMPONENTS_MANAGER')"
      :loading="loading"
      :open.sync="showChangeComponentsRepairManagerModal"
      :search-text="$t('COMMON.SEARCH_MANAGER')"
      :confirm-action="changeComponentsRepairManager"
      :user.sync="componentsNewManager"
    />
    <user-selector-modal
      v-if="canChangeTimesRepairManager(repair)"
      :filter-organization="repair.organization?.id"
      :filter-permission="$permissions.PERM_EDIT_REPAIRS_TIMES"
      :header-text="$t('REPAIRS.CHANGE_TIMES_MANAGER')"
      :loading="loading"
      :open.sync="showChangeTimesRepairManagerModal"
      :search-text="$t('COMMON.SEARCH_MANAGER')"
      :confirm-action="changeTimesRepairManager"
      :user.sync="timesNewManager"
    />
  </div>
</template>

<script>
import UserSelectorModal from "@/components/UserSelectorModal.vue";
import repairPermissionsMixin from "@/mixins/repair-permissions-mixin";

export default {
  name: "repair-view-repair-managers",

  components: {
    UserSelectorModal,
  },

  mixins: [repairPermissionsMixin],

  props: {
    repair: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const componentsNewManager = this.repair.startedComponentsBy?.id;
    const timesNewManager = this.repair.startedTimesBy?.id;
    return {
      componentsNewManager,
      showChangeComponentsRepairManagerModal: false,
      timesNewManager,
      showChangeTimesRepairManagerModal: false,
    };
  },

  methods: {
    changeComponentsRepairManager() {
      this.$emit("onChangeComponentsRepairManager", {
        repairId: this.repair.id,
        data: { user_id: this.componentsNewManager },
      });
    },
    changeTimesRepairManager() {
      this.$emit("onChangeTimesRepairManager", {
        repairId: this.repair.id,
        data: { user_id: this.timesNewManager },
      });
    },
  },

  watch: {
    showChangeComponentsRepairManagerModal(value) {
      if (!value) {
        this.componentsNewManager = this.repair.startedComponentsBy?.id;
      }
    },
    showChangeTimesRepairManagerModal(value) {
      if (!value) {
        this.timesNewManager = this.repair.startedTimesBy?.id;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
