var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"show":true,"modal-classes":"modal-secondary complete"}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/check-green.svg","alt":"icon"}})]),_c('p',{staticClass:"complete-text"},[_vm._v(_vm._s(_vm.$t("INSPECTIONS.COMPLETED")))]),_c('div',{staticClass:"vehicle-note"},[_c('ul',[_c('li',[_c('button',{on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.MODIFY"))+" "),_c('i',{staticClass:"far fa-edit"})])])])]),_c('div',{staticClass:"comment"},[_c('p',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.LEAVE_A_COMMENT")))]),_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 2, maxRows: 4 },"placeholder":"Message"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('template',{slot:"footer"},[_c('Button',{staticClass:"no",on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.MODIFY"))+" ")]),_c('Button',{staticClass:"yes",on:{"click":() => {
            _vm.showSignatureModal = true;
          }}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.SIGN"))+" ")])],1)],2),_c('modal',{attrs:{"show":_vm.showSignatureModal,"modal-classes":"modal-secondary signature"},on:{"update:show":function($event){_vm.showSignatureModal=$event}}},[_c('p',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.APPLY_SIGNATURE_AND_CONTINUE")))]),_c('div',{staticClass:"signature-inner"},[_c('VueSignaturePad',{ref:"signaturePad",attrs:{"id":"signature","width":"100%","height":"100%","options":{
          onBegin: () => {
            _vm.$refs.signaturePad.resizeCanvas();
          },
          minWidth: 0.5,
          maxWidth: 6,
        }}})],1),_c('template',{slot:"footer"},[_c('Button',{staticClass:"undo",on:{"click":_vm.undo}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.CLEAR"))+" ")]),_c('Button',{staticClass:"no",on:{"click":function($event){_vm.showSignatureModal = false}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CANCEL"))+" ")]),_c('Button',{staticClass:"yes",on:{"click":_vm.applySignatureAndSend}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.SIGN_AND_SEND"))+" ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }