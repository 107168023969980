<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!repair">
      <span class="loader"></span>
    </span>
    <repair-form
      v-if="repair"
      :formErrors="formErrors"
      :repairData="repair"
      :loading="loading"
      @formChanged="
        () => {
          alertLeave = true;
          get();
        }
      "
      @onViewRepair="onViewRepair"
      @onCancelRepair="onCancelRepair"
      @onCloseRepairModal="onCloseRepairModal"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import RepairForm from "../partials/RepairForm.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import repairPermissionsMixin from "@/mixins/repair-permissions-mixin";

export default {
  layout: "DashboardLayout",

  components: { RepairForm },

  mixins: [alertLeave, requestErrorMixin, repairPermissionsMixin],

  props: {
    repairId: {
      type: [String, Number],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      repair: null,
      formErrors: null,
    };
  },

  computed: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("repairs/get", this.repairId);
        this.repair = this.$store.getters["repairs/repair"];
        this.repair.vehicle_vin = this.repair.vehicle.vin;
        if (this.canEditRepair(this.repair)) {
          this.$emit("repairLoaded", this.repair);
        } else {
          this.$emit("onViewRepair", this.repair);
        }
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      }
    },

    async onViewRepair(repair = null) {
      if (!repair) {
        await this.get();
        repair = this.repair;
      }

      this.$emit("onViewRepair", repair);
    },

    async onCancelRepair() {
      await this.get();

      this.$emit("onCancelRepair", this.repair);
    },

    onCloseRepairModal() {
      this.$emit("onCloseRepairModal");
    },
  },
};
</script>
