<template>
  <div>
    <modal :show="true" modal-classes="modal-secondary complete">
      <div class="icon">
        <img src="/img/check-green.svg" alt="icon" />
      </div>
      <p class="complete-text">{{ $t("INSPECTIONS.COMPLETED") }}</p>
      <div class="vehicle-note">
        <ul>
          <li>
            <button @click="closeModal">
              {{ $t("INSPECTIONS.MODIFY") }} <i class="far fa-edit"></i>
            </button>
          </li>
        </ul>
      </div>
      <div class="comment">
        <p>{{ $t("INSPECTIONS.LEAVE_A_COMMENT") }}</p>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="Message"
          v-model="comment"
        >
        </el-input>
      </div>
      <template slot="footer">
        <Button class="no" @click="closeModal">
          {{ $t("INSPECTIONS.MODIFY") }}
        </Button>
        <Button
          class="yes"
          @click="
            () => {
              showSignatureModal = true;
            }
          "
        >
          {{ $t("INSPECTIONS.SIGN") }}
        </Button>
      </template>
    </modal>

    <modal
      :show.sync="showSignatureModal"
      modal-classes="modal-secondary signature"
    >
      <p>{{ $t("INSPECTIONS.APPLY_SIGNATURE_AND_CONTINUE") }}</p>
      <div class="signature-inner">
        <VueSignaturePad
          id="signature"
          width="100%"
          height="100%"
          ref="signaturePad"
          :options="{
            onBegin: () => {
              $refs.signaturePad.resizeCanvas();
            },
            minWidth: 0.5,
            maxWidth: 6,
          }"
        />
      </div>
      <template slot="footer">
        <Button class="undo" @click="undo">
          {{ $t("INSPECTIONS.CLEAR") }}
        </Button>
        <Button class="no" @click="showSignatureModal = false">
          {{ $t("COMMON.CANCEL") }}
        </Button>
        <Button class="yes" @click="applySignatureAndSend">
          {{ $t("INSPECTIONS.SIGN_AND_SEND") }}
        </Button>
      </template>
    </modal>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { VueSignaturePad } from "vue-signature-pad";
import { Button, Checkbox, CheckboxGroup } from "element-ui";

export default {
  name: "repair-view-repair-comment-and-sign",

  components: {
    [Button.name]: Button,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    VueSignaturePad,
  },

  props: ["repair"],

  data() {
    return {
      showSignatureModal: false,
      showCompleteModal: false,
      comment: "",
    };
  },

  computed: {},

  created() {},

  mounted() {
    this.showSignatureModal = false;
    this.showCompleteModal = false;
  },

  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },

    closeModal() {
      this.$emit("onCloseModal");
    },

    applySignatureAndSend() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        swal.fire({
          title: this.$t("INSPECTIONS.SIGNATURE_REQUIRED"),
          icon: "warning",
        });
        return;
      }
      this.$emit("onSignAndSend", this.comment, data);
    },
  },

  watch: {},
};
</script>
