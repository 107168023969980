<template>
  <form ref="itemForm" class="list-item">
    <div class="list-item-line">
      <div class="list-item-line-group flex-column align-items-stretch">
        <base-input
          v-model="itemModel.name"
          :label="`${$t('REPAIRS.REPAIR_ITEM')} (*)`"
          :placeholder="$t('REPAIRS.REPAIR_ITEM')"
          :name="$t('REPAIRS.REPAIR_ITEM')"
          :disabled="!editable"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.name" />
      </div>
      <div class="list-item-line-group flex-column align-items-stretch">
        <base-input
          v-model="itemModel.excerpt"
          :label="`${$t('REPAIRS.REPAIR_ITEM_DETAIL')}`"
          :placeholder="$t('REPAIRS.REPAIR_ITEM_DETAIL')"
          :disabled="!editable"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>
      <div class="list-item-line-group flex-column align-items-stretch">
        <base-input
          :label="`${$t('REPAIRS.REPAIR_ITEM_STATE')} (*)`"
          :placeholder="$t('REPAIRS.REPAIR_ITEM_STATE')"
          vid="component_condition"
          :disabled="!editable"
        >
          <el-select
            v-model="itemModel.component_condition"
            :placeholder="
              itemModel.component_condition
                ? ''
                : $t('REPAIRS.REPAIR_ITEM_STATE')
            "
            :disabled="!editable"
          >
            <el-option
              v-for="[key, condition] in Object.entries(
                conditionOptionsDisplay
              )"
              class="custom-select-option"
              :value="key"
              label=" "
              :key="key"
            >
              <div class="option-inner">
                <span class="icon" :class="condition?.color">
                  <i class="far" :class="condition?.iconName"></i>
                </span>
                <span class="text">{{ $t(`REPAIRS.${key}`) }}</span>
              </div>
            </el-option>
            <template v-if="itemModel.component_condition" #prefix>
              <div class="option-inner prefix">
                <span
                  class="icon"
                  :class="
                    conditionOptionsDisplay[itemModel.component_condition]
                      ?.color
                  "
                >
                  <i
                    class="far"
                    :class="
                      conditionOptionsDisplay[itemModel.component_condition]
                        ?.iconName
                    "
                  ></i>
                </span>
                <span class="text">{{
                  $t(`REPAIRS.${itemModel.component_condition}`)
                }}</span>
              </div>
            </template>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.component_condition" />
      </div>
      <div class="list-item-line-group buttons align-self-end" v-if="itemId">
        <base-button
          class="add-image position-relative"
          icon
          size="sm"
          @click="showPictureModal = true"
          :disabled="!editable"
        >
          <i class="far fa-camera"></i>
          <span class="badge d-inline-block" v-if="itemModel.gallery.length" />
        </base-button>
        <!-- <base-button
          class="add-comment"
          icon
          size="sm"
          @click="commentSectionModalOpened = true"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_COMMENTS) ||
            $currentUserCan($permissions.PERM_VIEW_COMMENTS)
          "
        >
          <i class="far fa-comment"></i>
          <span class="badge" v-if="itemModel.comments_count">
            {{ itemModel.comments_count }}
          </span>
        </base-button> -->
      </div>
    </div>

    <div class="list-item-line labor">
      <div class="part-labor">
        <div class="part-labor-item">
          <base-input
            v-model="itemModel.component_name"
            :label="`${$t('REPAIRS.REPAIR_ITEM_PART')}`"
            :placeholder="$t('REPAIRS.REPAIR_ITEM_PART')"
            :name="$t('REPAIRS.REPAIR_ITEM_PART')"
            :disabled="!editable"
          />
          <validation-error :errors="apiValidationErrors.component_name" />
        </div>
        <div class="part-labor-item">
          <base-input
            v-model="itemModel.component_code"
            :label="`${$t('REPAIRS.REPAIR_ITEM_PART_NUMBER')}`"
            :placeholder="$t('REPAIRS.REPAIR_ITEM_PART_NUMBER')"
            :disabled="!editable"
          />
          <validation-error :errors="apiValidationErrors.component_code" />
        </div>
        <div class="part-labor-item small">
          <base-input
            v-model.number="itemModel.component_quantity"
            :label="`${$t('COMMON.QUANTITY')}`"
            :placeholder="$t('COMMON.QUANTITY')"
            :name="`${$t('COMMON.QUANTITY')}`"
            type="number"
            min="0"
            :disabled="!editable"
          />
          <validation-error :errors="apiValidationErrors.component_quantity" />
        </div>
      </div>

      <div class="part-labor">
        <div class="part-labor-item">
          <base-input
            v-model="itemModel.component_details"
            :label="`${$t('REPAIRS.REPAIR_ITEM_MANUAL_LABOR')} (*)`"
            :placeholder="$t('REPAIRS.REPAIR_ITEM_MANUAL_LABOR')"
            :disabled="!editable"
          />
          <validation-error :errors="apiValidationErrors.component_details" />
        </div>
        <div class="part-labor-item small clear-input-number-arrows">
          <base-input
            v-model.number="itemModel.repair_time_hour"
            :label="`${$t('REPAIRS.REPAIR_ITEM_TIME')}`"
            :placeholder="$t('REPAIRS.REPAIR_ITEM_TIME')"
            input-group-classes="text-black border-2 purge-append-border input-group-text-p-2"
            type="number"
            append-icon="h"
            input-classes="border-right-0 p-2"
            min="0"
            :disabled="!editable"
          >
            <template #append><span class="text-black">h</span></template>
          </base-input>
          <validation-error :errors="apiValidationErrors.repair_time_hour" />
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-2">
      <div class="w-40 h-100">
        <base-button
          type="button"
          class="btn-outline-darker"
          @click="newItem"
          v-if="isLastItem"
        >
          <i class="fas fa-plus mr-1"></i> {{ $t("COMMON.ADD") }}
        </base-button>
        <base-button
          type="button"
          class="btn-danger"
          v-if="canRemoveItem"
          @click="removeItem"
        >
          <i class="fas fa-close mr-1"></i> {{ $t("COMMON.REMOVE") }}
        </base-button>
        <div class="d-inline-block" v-if="loading">
          <div class="custom-loading-icon"></div>
        </div>
      </div>

      <div class="list-item-line total w-auto p-2">
        <div class="radio-group">
          <base-checkbox v-model="itemModel.done" :disabled="!editable">
            {{ $t("REPAIRS.REPAIRED") }}
            <span class="check"></span>
          </base-checkbox>
          <validation-error :errors="apiValidationErrors.done" />
        </div>
      </div>
    </div>

    <div
      class="alert mt-3 text-black"
      role="alert"
      style="background-color: rgba(var(--primary-rgb), 0.2)"
      v-html="$t('REPAIRS.REVIEW_ALERT')"
      v-if="showReviewAlert"
    />

    <inspection-confirmation-modal
      :confirm-action="deleteItem"
      :confirm-button-text="$t('COMMON.YES_DELETE')"
      :loading="loading"
      :message="$t('REPAIRS.DELETE_THIS_ITEM_FROM_REPAIR')"
      :open.sync="showDeleteItemModal"
      modal-classes="modal-secondary cancel"
    />

    <modal
      :showClose="true"
      :show.sync="showPictureModal"
      modal-classes="picture"
    >
      <template slot="header">
        <h5 class="modal-title" id="inspection-picture">
          {{ $t("INSPECTIONS.ADD_PICTURES") }}
        </h5>
      </template>
      <div>
        <gallery-selector
          :label="$t('COMMON.PICTURES')"
          :defaultGallery="gallery"
          ressource_name="repair_items"
          :ressource_id="itemId"
          :field="itemModel.name"
          @galleryChanged="
            (gallery_urls) => {
              gallery = gallery_urls;
            }
          "
        />
      </div>
      <template slot="footer" v-if="editable">
        <base-button type="primary" class="btn save" @click="addImages">
          {{ $t("COMMON.SAVE") }}
        </base-button>
      </template>
    </modal>
    <comments-modal
      :open.sync="commentSectionModalOpened"
      :object="itemModel"
      @onCommentsChanged="get"
      :canAddComment="editable"
    />
  </form>
</template>
<script>
import { Button, Option, Select } from "element-ui";
import {
  repairItemConditionsOptionsDisplay,
  repairItemStatusesOption,
  REPAIR_ITEM_STATUS_APPROVED,
  REPAIR_ITEM_CONDITION_TO_REPLACE,
  REPAIR_ITEM_STATUS_DENIED,
  REPAIR_ITEM_STATUS_REVIEWED,
} from "@/constants/repairItems";
import formMixin from "@/mixins/form-mixin";
import BaseRadio from "@/components/Inputs/BaseRadio.vue";
import _ from "lodash";
import swal from "sweetalert2";
import ValidationError from "@/components/ValidationError.vue";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import CommentsModal from "@/components/Comments/CommentsModal.vue";
import { cloneDeep, isEqual, debounce } from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "repair-item-form",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    BaseRadio,
    ValidationError,
    InspectionConfirmationModal,
    GallerySelector,
    CommentsModal,
  },

  mixins: [formMixin, requestErrorMixin],

  props: {
    item: {
      type: Object,
      required: true,
    },
    canEditStatus: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    canShowStatus: {
      type: Boolean,
      default: false,
    },
    canRemoveItem: {
      type: Boolean,
      default: false,
    },
    canEditComponents: {
      type: Boolean,
      default: false,
    },
    canEditTimes: {
      type: Boolean,
      default: false,
    },
    isLastItem: {
      type: Boolean,
      default: false,
    },
    withoutComponents: {
      type: Boolean,
      default: false,
    },
    withoutTimes: {
      type: Boolean,
      default: false,
    },
    canShowReviewAlert: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    let repair = { ...this.item.repair };
    let itemId = this.item?.id;
    let itemModel = { ...this.item };
    const backupData = { ...itemModel };
    const gallery = itemModel.gallery;
    return {
      itemId,
      itemModel,
      backupData,
      repair,
      conditionOptionsDisplay: repairItemConditionsOptionsDisplay,
      statusOptions: repairItemStatusesOption,
      REPAIR_ITEM_CONDITION_TO_REPLACE,
      REPAIR_ITEM_STATUS_APPROVED,
      loading: false,
      formErrors: [],
      showDeleteItemModal: false,
      showPictureModal: false,
      commentSectionModalOpened: false,
      gallery,
    };
  },

  computed: {
    componentAmount: function () {
      if (this.withoutComponents) {
        return 0;
      }
      return (
        this.itemModel.component_quantity * this.itemModel.component_amount
      );
    },
    workAmount: function () {
      if (this.withoutTimes) {
        return 0;
      }
      return this.itemModel.repair_time_hour * this.itemModel.work_amount;
    },
    total: function () {
      return this.componentAmount + this.workAmount;
    },
    // readOnly: function () {
    //   return (
    //     !this.canEditComponents && !this.canEditTimes && !this.canEditStatus
    //   );
    // },
    canEditItems: function () {
      return this.canEditComponents || this.canEditTimes;
    },
    sendDataFiltered: function () {
      const item = { ...this.itemModel };
      if (this.itemId) {
        for (const [key, value] of Object.entries(item)) {
          if (value == this.backupData[key]) {
            delete item[key];
          }
        }
      }

      return { ...item };
    },
    showReviewAlert: function () {
      return (
        this.canShowReviewAlert &&
        this.itemModel.status == REPAIR_ITEM_STATUS_REVIEWED
      );
    },
  },

  created: function () {},

  beforeDestroy: function () {},

  mounted: function () {},

  methods: {
    openCommentModal() {
      this.$emit("openCommentModal");
    },

    newItem() {
      this.$emit("addNewItem");
    },

    async saveItem() {
      if (!this.editable) {
        return;
      }

      this.formErrors = [];

      const sendDataFiltered = cloneDeep(this.sendDataFiltered);
      delete sendDataFiltered.type;
      delete sendDataFiltered.relationshipNames;
      delete sendDataFiltered.repair;
      delete sendDataFiltered.organization;
      delete sendDataFiltered.links;

      if (!Object.keys(sendDataFiltered).length) return;

      const item = {
        type: "repair-items",
        relationshipNames: ["repair", "organization"],
        repair: this.repair,
        organization: this.backupData.organization,
        ...this.sendDataFiltered,
      };

      if (this.itemId) {
        this.updateItem(item);
      } else {
        this.addItem(item);
      }
    },

    async addItem(item) {
      this.loading = true;
      try {
        const result = await this.$store.dispatch("repairItems/add", item);
        let resultFiltered = { ...result };
        this.itemId = result.id;

        for (const key of Object.keys(item)) {
          delete resultFiltered[key];
        }

        this.itemModel = {
          ...this.itemModel,
          ...resultFiltered,
          ...this.sendDataFiltered,
        };
        this.backupData = { ...result, ...item };
        this.$emit("itemUpdated");
      } catch (error) {
        // this.$notify({
        //   type: "danger",
        //   message: "An error occurred while updating",
        // });
        this.formErrors = error.response?.data?.errors;
      } finally {
        this.loading = false;
      }
    },

    async updateItem(item) {
      this.loading = true;
      try {
        const result = await this.$store.dispatch("repairItems/update", {
          ...item,
          id: this.itemId,
        });
        let resultFiltered = { ...result };

        for (const key of Object.keys(item)) {
          delete resultFiltered[key];
        }

        this.itemModel = {
          ...this.itemModel,
          ...resultFiltered,
          ...this.sendDataFiltered,
        };
        this.backupData = { ...result, ...item };
        this.$emit("itemUpdated");
      } catch (error) {
        // this.$notify({
        //   type: "danger",
        //   message: "An error occurred while updating",
        // });
        this.formErrors = error.response.data.errors;
      } finally {
        this.loading = false;
      }
    },

    async get() {
      this.loading = true;
      try {
        const result = await this.$store.dispatch(
          "repairItems/get",
          this.itemId
        );

        this.itemModel = { ...this.itemModel, ...result };
        this.backupData = { ...this.itemModel, ...result };
        this.$emit("itemUpdated");
      } catch (error) {
        // this.$notify({
        //   type: "danger",
        //   message: "An error occurred while updating",
        // });
        this.formErrors = error.response.data.errors;
      } finally {
        this.loading = false;
      }
    },

    removeItem() {
      if (this.itemId) {
        this.showDeleteItemModal = true;
      } else {
        this.$emit("deleteItem");

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_ITEM_DELETED"),
        });
      }
    },

    async deleteItem() {
      try {
        this.loading = true;
        swal.showLoading();

        await this.$store.dispatch("repairItems/destroy", this.itemId);

        this.$emit("deleteItem");

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_ITEM_DELETED"),
        });
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.showDeleteItemModal = false;
        this.loading = false;
      }
    },
    addImages() {
      this.itemModel = { ...this.itemModel, gallery: this.gallery };
      this.$nextTick(function () {
        this.showPictureModal = false;
      });
    },

    saveItemDebounced: debounce(function () {
      this.saveItem();
    }, 500),
  },

  watch: {
    itemModel: {
      handler: function (newValue, oldValue) {
        if (this.readOnly) return;

        this.$emit("update:item", { ...newValue, id: this.itemId });

        if (
          [REPAIR_ITEM_STATUS_DENIED, REPAIR_ITEM_STATUS_REVIEWED].includes(
            newValue.status
          ) &&
          newValue.status != this.backupData.status
        ) {
          this.commentSectionModalOpened = true;
        }

        if (newValue.status != oldValue.status) {
          this.saveItem();
        } else {
          this.saveItemDebounced();
        }
      },
      deep: true,
    },
    item: {
      handler: function () {
        const newObject = cloneDeep(this.item);
        const oldObject = cloneDeep(this.itemModel);

        delete oldObject.relationshipNames;
        delete oldObject.repair;
        delete oldObject.organization;
        delete oldObject.links;

        delete newObject.relationshipNames;
        delete newObject.repair;
        delete newObject.organization;
        delete newObject.links;

        if (isEqual(newObject, oldObject)) return;

        this.itemModel = { ...this.itemModel, ...newObject };
      },
      deep: true,
    },
    // item() {
    //   this.itemModel = cloneDeep(this.item);
    // },
    formErrors(errors) {
      this.setApiValidation(errors);
    },
    showPictureModal(value) {
      if (!value) {
        this.gallery = this.itemModel.gallery;
      }
    },
  },
};
</script>
<style lang="scss">
.w-60 {
  width: 60% !important;
}
.w-40 {
  width: 40% !important;
}
.clear-input-number-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }
}
.input-group-text-p-2 {
  .input-group-text {
    padding: 0.5rem !important;
  }
}
.text-black {
  color: #000 !important;
}
.estimate-details {
  .form-group {
    .input-group-text {
      // border-top-left-radius: 0;
      // border-bottom-left-radius: 0;
      // border-top-right-radius: 8px;
      // border-bottom-right-radius: 8px;
      border-radius: 0 8px 8px 0;
    }
  }
}
</style>
