<template>
  <div class="field-images">
    <div
      v-for="(images, name) in repair.images"
      :key="name"
      class="field-images-item"
    >
      <div class="field-images-item-name">
        {{ name }}
      </div>
      <div class="field-images-item-images">
        <div
          class="field-images-item-images-item"
          v-for="(imageUrl, idx) in images"
          :key="`${name}-${idx}`"
        >
          <img :src="imageUrl" alt="logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "repair-view-images",

  components: {},

  props: ["repair"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
