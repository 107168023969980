import { ESTIMATE_ITEM_CONDITION_TO_REPLACE } from "@/constants/estimateItems";

export default {
  type: "repair-items",
  name: "",
  done: false,
  status: "",
  excerpt: "",
  component_code: "",
  component_name: "",
  component_amount: 0,
  component_quantity: 0,
  component_condition: "",
  component_details: "",
  repair_time_hour: 0,
  work_amount: 0,
  relationshipNames: ["repair", "organization"],
  repair: {
    type: "repairs",
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
};
